const AnchorLink = ({
  className,
  href,
  target,
  children,
  style,
  onClick,
  rel,
  locale,
}) => {
  const localeEn = locale === "en" ? "en" : null;
  const hrefEn = href.startsWith("/en") ? href.substring(3) : href;

  return (
    <a
      className={className}
      style={style}
      target={target}
      onClick={onClick}
      rel={rel}
      locale={locale}
      href={localeEn ? `/${localeEn + hrefEn}` : hrefEn}
    >
      {children}
    </a>
  );
};

export default AnchorLink;
