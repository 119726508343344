import { useEffect, useState } from "react";

export const isExists = (value) => {
  let isExists = true;
  switch (value) {
    case null:
      isExists = false;
      break;
    case undefined:
      isExists = false;
      break;
    case "":
      isExists = false;
      break;
    case "undefined":
      isExists = false;
      break;
  }
  return isExists;
};

function isEmpty(obj) {
  if (
    obj === "" ||
    obj === null ||
    JSON.stringify(obj) === "{}" ||
    JSON.stringify(obj) === "[]" ||
    obj === undefined
  ) {
    return true;
  } else {
    return false;
  }
}

export function clean(values) {
  let object = Object.assign({}, values);
  if (typeof object !== "object") {
    return object;
  }
  let oKeys = Object.keys(object);
  for (let j = 0; j < oKeys.length; j++) {
    let p = oKeys[j];
    switch (typeof object[p]) {
      case "object":
        if (Array.isArray(object[p])) {
          let clonedValue = [...object[p]];
          for (let i = 0; i < clonedValue.length; i++) {
            clonedValue[i] = clean(clonedValue[i]);
            if (isEmpty(clonedValue[i])) {
              clonedValue.splice(i, 1);
              i--;
            }
          }
          if (clonedValue.length === 0) {
            if (Array.isArray(object)) {
              object.splice(parseInt(p), 1);
              j--;
            } else {
              delete object[p];
            }
          }
        } else {
          if (object[p] instanceof Date) break;

          if (isEmpty(object[p])) {
            try {
              delete object[p];
            } catch {
              break;
            }
          } else {
            object[p] = clean(object[p]);
            if (isEmpty(object[p])) delete object[p];
          }
        }
        break;
      default:
        if (isEmpty(object[p])) {
          delete object[p];
        }
        break;
    }
  }
  if (Object.keys(object).length === 0) {
    return;
  }
  return object;
}

export const fetcher = async (source) => {
  const response = await fetch(`${process.env.ISNETURL}/${source}`).then(
    (res) => res.json()
  );
  return response;
};

export const fetcherLocale = async (source) => {
  const response = await fetch(process.env.API_URL + "/" + source).then((res) =>
    res.json()
  );
  return response;
};

export function groupBy(list, keyGetter) {
  const map = new Map();
  list?.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function groupByForDropdown(list, keyGetter) {
  const map = new Map();
  list?.forEach((item) => {
    const label = keyGetter(item);
    const collection = map.get(label);
    if (!collection) {
      map.set(label, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function staticPath(data) {
  const grouped = groupBy(data, (item) => item.parentId);

  data?.map((item) => {
    item.children = grouped.get(item.id) ?? null;
    if (item.children > 0) {
      item.children.map((element, index) => {
        const child = grouped.get(element.children[index].id);
        element.children[index].children = child ?? null;
      });
    }
  });
  const nav = data?.filter(
    (element) => element.parentId === undefined || element.parentId === null
  );

  return nav;
}

export const useWindowDimensions = () => {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
};

export const Loader = () => <div>Loading...</div>;

export const contains = (arr1, mainObj, key) => {
  arr1.map((el) => console.log(el[key] in mainObj));
};
export const includes = (arr1, mainObj, key) => {
  const arr2 = arr1.filter((el) => el[key] in mainObj);
  return arr2.every((el) => el.categoryName === "e-Dönüşüm");
};

export const getLayoutData = async () => {
  const [navbarData, navbarFooterData, footerData] = await Promise.all([
    fetch(`${process.env.ISNETURL}/menu`).then((res) => res.json()),
    fetch(`${process.env.ISNETURL}/menu/footermenu`).then((res) => res.json()),
    fetch(`${process.env.API_URL}/footer`).then((res) => res.json()),
  ]);

  const navbarFilter =
    navbarData && navbarData?.filter((item) => item.isDeleted === false);

  let navbar, footer;
  if (navbarFilter || footerData) {
    const nav = staticPath(navbarFilter);
    navbar = nav;
    footer = footerData;
  }

  return {
    navbar,
    footer,
    navbarData,
    navbarFooterData,
  };
};
