import Link from "next/link";
import { useRouter } from "next/router";
import { Loader, fetcher } from "../../utils";
import { useEffect, useState, useCallback } from "react";
import { Spinner } from "react-bootstrap";

const AnchorLinkLanguage = ({ className, target, children, style, rel }) => {
  const { query, locale, asPath } = useRouter();
  const router = useRouter();
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getPageByLanguage = useCallback(async () => {
    setIsLoading(true);
    if (query.page) {
      await fetcher(`page/test/${query.page}`).then(async (dataFromSlug) => {
        if (dataFromSlug.mainGroupId) {
          await fetcher(
            `page/get-by-maingroupid/${locale === "tr" ? 2 : 1}/${
              dataFromSlug.mainGroupId
            }`
          )
            .then(async (pageByMainGroupId) => {
              if (pageByMainGroupId[0]?.menuId) {
                await fetcher(`menu/${pageByMainGroupId[0].menuId}`).then(
                  async (dataMenu) => {
                    if (dataMenu.href) {
                      await fetcher(`menu/${dataMenu.parentId}`).then(
                        async (dataMenuSlug) => {
                          setIsLoading(false);
                          if (dataMenuSlug.href) {
                            await fetcher(`menu/${dataMenuSlug.parentId}`).then(
                              async (dataMenuMain) => {
                                setUrl(
                                  `/${dataMenuMain.href}/${dataMenuSlug.href}/${pageByMainGroupId[0].urlName}`
                                );
                              }
                            );
                          }
                        }
                      );
                    }
                  }
                );
              } else {
                setIsLoading(false);
                setUrl(null);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setUrl(null);
        }
      });
    } else if (query.main && query.slug && !query.page) {
      await fetcher(`page/test/${query.slug}`).then(async (dataFromSlug) => {
        if (dataFromSlug.mainGroupId) {
          await fetcher(
            `page/get-by-maingroupid/${locale === "tr" ? 2 : 1}/${
              dataFromSlug.mainGroupId
            }`
          ).then(async (pageByMainGroupId) => {
            if (pageByMainGroupId[0].menuId) {
              await fetcher(`menu/${pageByMainGroupId[0].menuId}`).then(
                async (dataMenu) => {
                  if (dataMenu.href) {
                    await fetcher(`menu/${dataMenu.parentId}`).then(
                      async (dataMenuParent) => {
                        setUrl(
                          `/${dataMenuParent.href}/${pageByMainGroupId[0].urlName}`
                        );
                        setIsLoading(false);
                      }
                    );
                  }
                }
              );
            }
          });
        } else {
          setIsLoading(false);
          setUrl(null);
        }
      });
    } else {
      setIsLoading(false);
      setUrl(null);
    }
    if (locale === "tr" && asPath === "/iletisim/urunbasvuruformu") {
      setUrl("/en/contact-us/product-application-form");
    }
    if (locale === "en" && asPath === "/contact-us/product-application-form") {
      setUrl("/iletisim/urunbasvuruformu");
    }
    if (locale === "tr" && asPath === "/iletisim/teknik-destek-talebi") {
      setUrl("/en/contact-us/suggestion-complaint-form");
    }
    if (locale === "en" && asPath === "/contact-us/suggestion-complaint-form") {
      setUrl("/iletisim/teknik-destek-talebi");
    }
    if (locale === "tr" && asPath === "/iletisim/tuketicionerivesikayetleri") {
      setUrl("/en/contact-us/suggestion-complaint-form");
    }
  });
  useEffect(() => {
    getPageByLanguage();
  }, [locale, query]);
  return (
    <>
      {!isLoading ? (
        <Link
          locale={locale === "en" ? "tr" : "en"}
          href={url ? url : "/"}
          style={style}
          target={target}
          rel={rel}
          passHref={false}
          legacyBehavior
        >
          <span className={className}>{children}</span>
        </Link>
      ) : (
        <Spinner color="secondary" />
      )}
    </>
  );
};

export default AnchorLinkLanguage;
